import { motion } from "framer-motion";
import PostPreview from "../components/post-preview";

const sideVariants = {
  closed: {
    transition: {
      staggerChildren: 0.1,
      staggerDirection: -1,
    },
  },
  open: {
    transition: {
      staggerChildren: 0.1,
      staggerDirection: 1,
    },
  },
};

const itemVariants = {
  closed: {
    opacity: 0,
    top: 40,
    scale: 0.8,
  },
  open: { opacity: 1, top: 0, scale: 1 },
};

export default function MoreStories({ posts }) {
  return (
    <section className="more-stories max-w-7xl mx-auto">
      <h2 className="case-studies-title mb-6 md:mb-8 text-xl md:text-2xl lg:text-3xl tracking-tighter leading-tight">
        Case studies
      </h2>
      <motion.div
        className="grid grid-cols-1 gap-y-8 lg:gap-y-16"
        initial="closed"
        whileInView="open"
        exit="closed"
        variants={sideVariants}
        viewport={{ once: true }}
      >
        {posts.map((post) => (
          <motion.div
            className="relative"
            key={post.slug}
            variants={itemVariants}
          >
            <PostPreview
              title={post.title}
              coverImage={post.heroImage}
              date={post.publishDate}
              author={post.author}
              slug={post.slug}
              description={post.description}
              tags={post.tags}
              video={post.video}
            />
          </motion.div>
        ))}
      </motion.div>
    </section>
  );
}
