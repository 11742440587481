import Link from "next/link";
import cn from "classnames";
import DateComponent from "../components/date";
import CoverImage from "./cover-image";

export default function PostPreview({
  title,
  coverImage,
  date,
  description,
  slug,
  tags,
  video,
}) {
  return (
    <div
      className={cn("project relative", {
        video: video,
        "no-video": !video,
      })}
    >
      <CoverImage
        title={title}
        slug={slug}
        url={coverImage.url}
        video={video}
        width={coverImage.width}
      />

      <Link className="project-info mt-5" href={`/posts/${slug}`}>
        <div className="flex justify-between items-end mb-2">
          <h3 className="text-2xl md:text-3xl font-bold leading-none">
            <span className="hover:text-blue-300 transition-colors">
              {title}
            </span>
          </h3>
          <div className="text-sm ml-4">
            <DateComponent dateString={date} />
          </div>
        </div>
        <p className="text-base md:text-lg mb-4 hidden lg:block">
          {description}
        </p>
        <p className="text-xs lg:text-sm uppercase leading-relaxed">
          {tags.map((tag) => (
            <span className="tag" key={tag}>
              {tag}
            </span>
          ))}
        </p>
      </Link>
    </div>
  );
}
