import Image from "next/image";

const contentfulLoader = ({ src, quality, width }) => {
  const url = new URL(`${src}`);

  url.searchParams.set("fm", "webp");
  url.searchParams.set("w", width.toString());
  url.searchParams.set("q", quality.toString() || "75");
  return url.href;
};

const ContentfulImage = (props) => {
  return (
    <Image
      loader={contentfulLoader}
      {...props}
      width={1920}
      height={1080}
      sizes="(max-width: 768px) 50vw,
      (max-width: 1200px) 50vw,
      100vw"
    />
  );
};

export default ContentfulImage;
