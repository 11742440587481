import { motion } from "framer-motion";
import ContentfulImage from "./contentful-image";
import Link from "next/link";
import cn from "classnames";

export default function CoverImage({ title, url, slug, priority, video }) {
  const image = (
    <ContentfulImage
      alt={`Cover Image for ${title}`}
      className={cn("project-image", {
        "link project-image": slug,
      })}
      src={url}
      width="1920"
      quality="75"
      priority={priority ? priority : false}
    />
  );

  return (
    <motion.div
      className="project-image-wrapper"
      onHoverStart={(e) => {
        let video = e.target.childNodes[0]?.childNodes[1]?.childNodes[0];
        if (video) video.play();
      }}
      onHoverEnd={(e) => {
        let video = e.target.childNodes[0]?.childNodes[1]?.childNodes[0];
        if (video) video.pause();
      }}
    >
      {slug ? (
        <Link
          href={`/posts/${slug}`}
          aria-label={title}
          className="project-image-link overflow-hidden bg-blue-300"
        >
          {image}
          {video && video.url && (
            <div className="project-video-wrapper absolute -top-8 right-8">
              <video
                className="project-video"
                loop
                muted
                playsInline
                width="366"
                src={video.url}
              />
            </div>
          )}
        </Link>
      ) : (
        image
      )}
    </motion.div>
  );
}
