import { useRef } from "react";
import { motion } from "framer-motion";
import Container from "../components/container";
import SectionSeparator from "../components/section-separator";
import MoreStories from "../components/more-stories";
import Layout from "../components/layout";
import { getAllPostsForHome } from "../lib/api";
import Head from "next/head";

const sideVariants = {
  closed: {
    transition: {
      staggerChildren: 0.2,
      staggerDirection: -1,
    },
  },
  open: {
    transition: {
      staggerChildren: 0.2,
      staggerDirection: 1,
    },
  },
};

const itemVariants = {
  closed: {
    opacity: 0,
    top: 40,
  },
  open: { opacity: 1, top: 0 },
};

export default function Index({ preview, allPosts, banner, person, stages }) {
  // const heroPost = allPosts[0];
  // const morePosts = allPosts.slice(1);
  const paraRef = useRef(null);
  const clickHandler = () => {
    paraRef.current &&
      paraRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  return (
    <>
      <Layout preview={preview}>
        <Head>
          <title>Salazarpardo Design Portfolio</title>
        </Head>
        <Container>
          <div className="lg:flex items-center justify-center pb-8 max-w-6xl mx-auto min-h-screen">
            <motion.div
              className="banner relative mb-2 md:mb-0 md:pr-8 lg:pr-20 max-w-3xl"
              dangerouslySetInnerHTML={{
                __html: banner.replace(/href/g, "target='_blank' href"),
              }}
              initial={{ opacity: 0, top: 80 }}
              whileInView={{ opacity: 1, top: 0 }}
              transition={{ delay: 0.25 }}
              viewport={{ once: true }}
            ></motion.div>
            <motion.video
              className="relative max-w-full w-xs rounded-[36px] lg:max-w-none lg:rounded-[42px] mx-auto lg:mb-16 xl:mb-32"
              src={person.reel.url}
              width="375"
              muted
              autoPlay
              loop
              playsInline
              initial={{ opacity: 0, top: -40, scale: 0.8 }}
              whileInView={{ opacity: 1, top: 0, scale: 1 }}
              transition={{ delay: 0.5 }}
              viewport={{ once: true }}
              poster={"/images/poster.png"}
            ></motion.video>
          </div>
          <motion.p
            className="text-center max-w-6xl mx-auto mt-12 lg:text-left lg:-mt-48 xl:-mt-60"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
          >
            <button
              className="flex items-center hover:text-blue-300 transition-colors"
              onClick={clickHandler}
            >
              Or read our case studies{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4 ml-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </button>
          </motion.p>
          <SectionSeparator />

          <div className="case-studies" ref={paraRef}>
            {allPosts.length > 0 && <MoreStories posts={allPosts} />}
          </div>
          <SectionSeparator />
          <motion.div
            className="stages banner py-12 md:py-24"
            initial="closed"
            whileInView="open"
            variants={sideVariants}
            viewport={{ once: true }}
          >
            <h4 className="text-3xl font-bold mb-24">{person.contact}</h4>
            <div className="grid gap-8 grid-cols-1 md:grid-cols-3">
              {stages.map((stage) => (
                <motion.div
                  className="relative mb-2 md:mb-8 lg:mb-12"
                  key={stage.title}
                  variants={itemVariants}
                >
                  <img
                    className="mb-4"
                    src={stage.icon?.url}
                    width="48"
                    height="48"
                    alt={`${stage.title} icon`}
                  ></img>
                  <h3 className="text-2xl mb-4">{stage.title}</h3>
                  <p>{stage.description}</p>
                </motion.div>
              ))}
            </div>
            <p className="text-left">
              <a
                href="https://calendly.com/salazarpardo/intro"
                target="_blank"
                title="Calendly link"
              >
                Book a call
              </a>
            </p>
          </motion.div>
        </Container>
      </Layout>
    </>
  );
}

export async function getStaticProps({ preview = false }) {
  const data = (await getAllPostsForHome(preview)) ?? [];
  return {
    props: {
      preview,
      allPosts: data?.entries ?? null,
      person: data?.person ?? null,
      banner: data?.banner ?? null,
      stages: data?.stages ?? null,
    },
    // revalidate: 60,
  };
}
